<template>
	<b-input-group-append is-text>
		<b-icon-eye-fill
			v-if="!visible"
			variant="secondary"
			class="cursor-pointer"
			@click="$emit('change', !visible)"
		/>
		<b-icon-eye-slash-fill
			v-else
			variant="secondary"
			class="cursor-pointer"
			@click="$emit('change', !visible)"
		/>
	</b-input-group-append>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				required: true
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
