<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<b-sidebar
		id="auth"
		ref="sidebar"
		right
		shadow
		backdrop
		no-header
		width="380px"
		@shown="renderGoogleButton"
		@hidden="destroy"
	>
		<div v-if="authComponent === 'login' && !otpVerificationType" id="login" class="p-3">
			<b-row>
				<b-col md="8">
					<h5>{{ $t('login') | capitalize }}</h5>
					<p>{{ $t('or') }} <a href="#" @click.prevent="authComponent = 'signup'">{{ $t('create an account') }}</a></p>
				</b-col>
				<b-col md="4" />
			</b-row>
			<validation-observer ref="validator">
				<validation-provider
					v-if="inputType === 'mobile'"
					ref="mobileValidator"
					v-slot="{ errors }"
					vid="phone.number"
					:name="$t('mobile number')"
					rules="mobile|mobileRequired"
				>
					<b-form-group
						label-for="mobile-number"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<input v-model="userName" type="hidden">
						<vue-tel-input
							:value="userName.number"
							:input-options="{
								id: 'mobile-field',
								placeholder: $t('mobile number'),
								styleClasses: ['form-control-md text-capitalize']
							}"
							:disabled-formatting="true"
							:default-country="merchant.settings.general.country_code || ''"
							:disabled-fetching-country="merchant.settings.general.country_code ? true : false"
							@input="($event, data) => {
								validateInputType($event, data)
							}"
						/>
					</b-form-group>
				</validation-provider>
				<validation-provider
					v-else
					ref="emailValidator"
					v-slot="{ errors }"
					:name="$t('email / mobile number')"
					rules="email|required"
				>
					<b-form-group
						label-for="email"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<b-form-input
							id="email-field"
							:value="userName"
							:state="errors.length ? false : null"
							type="text"
							:placeholder="`${$options.filters.capitalize($t('email / mobile number'))}`"
							size="md"
							@input="validateInputType($event)"
						/>
					</b-form-group>
				</validation-provider>
				<validation-provider
					v-slot="{ errors }"
					:name="$t('password')"
					rules="required|min:8|max:32"
				>
					<b-form-group
						v-if="showPassword"
						label-for="password"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<b-input-group size="md">
							<b-form-input
								v-model="password"
								:state="errors.length ? false : null"
								:type="viewLoginPassword ? 'text' : 'password'"
								:placeholder="$options.filters.capitalize($t('password'))"
							/>
							<toggle-password :visible="viewLoginPassword" @change="viewLoginPassword = $event" />
						</b-input-group>
						<div slot="description" class="text-right">
							<a href="#" class="text-capitalize" @click="forgotPassword">{{ $t('forgot password') }}</a>
						</div>
					</b-form-group>
				</validation-provider>
			</validation-observer>
			<b-button
				v-if="showPassword"
				variant="primary"
				size="sm"
				block
				class="text-capitalize"
				:disabled="loading"
				@click="validateLogin"
			>
				{{ $t('login') }}
			</b-button>
			<b-button
				v-else
				variant="primary"
				size="sm"
				block
				class="text-capitalize"
				:disabled="loading"
				@click="verifyAccount"
			>
				{{ $t('next') }}
			</b-button>
			<p
				v-if="merchant.settings.integrations.google_sign_in.enabled
					|| merchant.settings.integrations.facebook_login.enabled"
				class="line-with-words my-4"
			>
				<span class="bg-light">or</span>
			</p>
			<div
				id="google-button"
				class="mb-3"
			/>
			<button
				v-if="merchant.settings.integrations.facebook_login.enabled"
				class="fb-button"
				:disabled="loading"
				@click="connectSocialPlatform('facebook')"
			>
				<div class="d-flex justify-content-between align-items-center">
					<b-icon-facebook variant="primary" font-scale="1.5" />
					<p class="mb-0">
						{{ $t('signinFb') }}
					</p>
					<span />
				</div>
			</button>
		</div>
		<div v-else-if="authComponent === 'signup' && !otpVerificationType" id="signup" class="p-3">
			<b-row>
				<b-col md="8">
					<h5>{{ $t('signup') | capitalize }}</h5>
					<p>{{ $t('or') }} <a href="#" @click.prevent="authComponent = 'login'">{{ $t('loginInToAccount') }}</a></p>
				</b-col>
				<b-col md="4" />
			</b-row>
			<validation-observer ref="validator">
				<validation-provider
					v-slot="{ errors }"
					:name="$t('name')"
					rules="required|alpha_spaces"
				>
					<b-form-group
						label-for="name"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<b-form-input
							id="name"
							v-model="name"
							:state="errors.length ? false : null"
							type="text"
							:placeholder="$options.filters.capitalize($t('name'))"
							size="md"
						/>
					</b-form-group>
				</validation-provider>
				<validation-provider
					ref="mobileValidator"
					v-slot="{ errors }"
					vid="phone.number"
					:name="$t('mobile number')"
					rules="mobileRequired|mobile"
				>
					<b-form-group
						label-for="mobile-number"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<input v-model="mobile" type="hidden">
						<vue-tel-input
							:value="mobile.number"
							:input-options="{
								id: 'mobile-number',
								placeholder: $t('mobile number'),
								styleClasses: ['form-control-md text-capitalize']
							}"
							:disabled-formatting="true"
							:default-country="merchant.settings.general.country_code || ''"
							:disabled-fetching-country="merchant.settings.general.country_code ? true : false"
							@input="($event, data) => {
								mobile = {
									code: `+${data.countryCallingCode}`,
									number: data.nationalNumber,
									country: {
										code: data.country?.iso2,
										name: data.country?.name
									},
									isValid: data.valid
								}
							}"
						/>
					</b-form-group>
				</validation-provider>
				<validation-provider
					v-slot="{ errors }"
					:name="$t('email')"
					:rules="{
						email: true,
						required: otpSource === 'email'
					}"
				>
					<b-form-group
						label-for="email"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<b-form-input
							id="email"
							v-model="email"
							:state="errors.length ? false : null"
							type="text"
							autocomplete="new-email"
							:placeholder="`${$options.filters.capitalize($t('email'))} ${otpSource === 'email' ? '' : `(${$t('optional')})`}`"
							size="md"
						/>
					</b-form-group>
				</validation-provider>
				<validation-provider
					v-slot="{ errors }"
					:name="$t('password')"
					rules="required|min:8|max:32"
				>
					<b-form-group
						label-for="password"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<b-input-group size="md">
							<b-form-input
								id="password"
								v-model="password"
								autocomplete="new-password"
								:state="errors.length ? false : null"
								:type="viewSignupPassword ? 'text' : 'password'"
								:placeholder="$options.filters.capitalize($t('password'))"
							/>
							<toggle-password :visible="viewSignupPassword" @change="viewSignupPassword = $event" />
						</b-input-group>
					</b-form-group>
				</validation-provider>
				<validation-provider
					v-if="merchant.subscription.products && merchant.subscription.products.referral"
					v-slot="{ errors }"
					vid="referral_code"
					:name="$t('referral code')"
					rules="alpha_num|length:8"
				>
					<b-form-group
						label-for="referral-code"
						:state="errors.length === 0 ? true : false"
						:invalid-feedback="errors[0]"
					>
						<b-form-input
							id="referral-code"
							v-model="referralCode"
							:state="errors.length ? false : null"
							type="text"
							:placeholder="$options.filters.capitalize($t('referral code'))"
							size="md"
						/>
					</b-form-group>
				</validation-provider>
			</validation-observer>
			<div
				id="google-button"
				class="mb-3"
			/>
			<button
				v-if="merchant.settings.integrations.facebook_login.enabled"
				class="fb-button mb-3"
				:disabled="loading"
				@click="connectSocialPlatform('facebook')"
			>
				<div class="d-flex justify-content-between align-items-center">
					<b-icon-facebook variant="primary" font-scale="1.5" />
					<p class="mb-0">
						{{ $t('signupFb') }}
					</p>
					<b-icon :icon="facebook ? 'check-circle-fill' : ''" />
				</div>
			</button>
			<b-button
				variant="primary"
				size="sm"
				class="text-capitalize mb-2"
				block
				:disabled="loading"
				@click="signUp"
			>
				{{ $t('create account') }}
			</b-button>
			<small class="text-muted">{{ $t('byCreatingAccount') }} <b-link to="/legal/terms-conditions" class="text-capitalize">
				{{ $t('t&c') }}
			</b-link></small>
		</div>
		<otp-verification
			v-if="otpVerificationType"
			:type="otpVerificationType"
			:otp-medium="otpMedium"
			class="p-3"
			@done="otpVerificationCallback"
		/>
	</b-sidebar>
</template>

<script>
	import jwtDecode from 'jwt-decode'
	import { VueTelInput } from 'vue-tel-input'
	import { ValidationObserver, ValidationProvider } from 'vee-validate'
	import TogglePassword from '@/components/toggle-password'
	import OtpVerification from '@/components/otp-verification'

	const initialState = () => {
		return {
			name: '',
			mobile: {
				code: '',
				number: ''
			},
			email: '',
			password: '',
			viewLoginPassword: false,
			viewSignupPassword: false,
			google: null,
			facebook: null,
			loading: false,
			showPassword: false,
			otpVerificationType: null,
			inputType: 'email',
			otpMedium: '',
			userName: ''
		}
	}

	export default {
		components: {
			VueTelInput,
			TogglePassword,
			OtpVerification,
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return initialState()
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			authComponent: {
				get () {
					return this.$store.state.authComponent
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'authComponent',
						value
					})
				}
			},
			referralCode: {
				get () {
					return this.$store.state.referralCode
				},
				set (value) {
					this.$store.commit('setState', { key: 'referralCode', value })
				}
			},
			otpSource () {
				return this.$store.state.merchant.settings.general.registration_verification_source
			}
		},
		watch: {
			authComponent () {
				this.renderGoogleButton()
			}
		},
		mounted () {
			this.initGoogleSdk(this.merchant.settings.integrations.google_sign_in.client_id, this.googleCallback)
			this.initFacebookSdk(this.merchant.settings.integrations.facebook_login.app_id)
		},
		methods: {
			validateInputType ($event, data) {
				this.inputType = ($event.length && /^(\d+\s*)+$/.test($event)) ? 'mobile' : 'email'

				if (this.inputType === 'mobile') {
					if (data?.countryCallingCode) {
						this.userName = {
							code: `+${data?.countryCallingCode}`,
							number: data.nationalNumber,
							country: {
								code: data.country?.iso2,
								name: data.country?.name
							},
							isValid: data.valid
						}
					} else {
						this.userName = {
							country: {
								code: this.merchant.settings.general.country_code,
								name: ''
							},
							number: $event
						}
					}
				} else if (this.inputType === 'email') {
					this.userName = $event
				}

				setTimeout(() => {
					const element = document.getElementById(`${this.inputType}-field`)

					element?.focus()
				}, 100)
			},
			renderGoogleButton () {
				if (window.google && this.merchant.settings.integrations.google_sign_in.enabled) {
					window.google.accounts.id.renderButton(
						document.getElementById('google-button'), {
							theme: 'outline',
							size: 'large',
							text: this.authComponent === 'signup' ? 'signup_with' : 'signin_with'
						})
				}
			},
			googleCallback (response) {
				const userDetail = jwtDecode(response.credential)

				this.processSocialData({
					name: 'google',
					type: this.authComponent,
					data: {
						id: userDetail.sub,
						name: userDetail.name,
						given_name: userDetail.given_name,
						family_name: userDetail.family_name,
						image_url: userDetail.picture,
						email: userDetail.email,
						email_verified: userDetail.email_verified
					}
				})

				this.email = userDetail.email
				this.name = userDetail.name
			},
			async signUp () {
				if (await this.$refs.validator.validate()) {
					this.loading = true
					this.$store.dispatch('register', {
						name: this.name,
						phone: {
							code: this.mobile.code,
							number: this.mobile.number,
							country: this.mobile.country
						},
						email: this.email,
						password: this.password,
						referral_code: this.referralCode,
						gp_user_id: this.google ? this.google.id : null,
						gp_data: this.google,
						fb_user_id: this.facebook ? this.facebook.id : null,
						fb_data: this.facebook
					}).then((response) => {
						if (response.code === 201) {
							this.otpMedium = this.otpSource === 'email' ? this.email : `${this.mobile.code} - ${this.mobile.number}`

							this.otpVerificationType = 'signup'
						}
					}).catch(this.showErrors).finally(() => {
						this.loading = false
					})
				}
			},
			otpVerificationCallback (hideSidebar) {
				if (hideSidebar) {
					this.$refs.sidebar.hide()
				}

				this.otpVerificationType = null
			},
			async verifyAccount () {
				if ((await this.$refs[`${this.inputType}Validator`]?.validate())?.valid) {
					this.loading = true

					const payload = {}

					if (this.inputType === 'mobile') {
						payload.phone = {
							code: this.userName.code,
							number: this.userName.number,
							country: this.userName.country
						}
					} else {
						payload.email = this.userName
					}

					this.$store.dispatch('verifyAccount', {
						...payload
					}).then((response) => {
						if (response.code === 200) {
							this.showPassword = true
							this.focusInput('#login input[type="password"]')
						}

						this.loading = false
					}).catch((err) => {
						if (err.response && err.response.status === 404) {
							this.authComponent = 'signup'
							this.showToast(this.$options.filters.capitalize(this.$t('toastMessage.createAccount')), {
								title: this.$options.filters.capitalize(this.$t(this.inputType === 'mobile' ? 'mobileNotReg' : 'emailNotReg')),
								variant: 'warning',
								toaster: 'b-toaster-top-center'
							})
						} else {
							this.showErrors(err)
						}

						this.loading = false
					})
				}
			},
			login (data) {
				const accountLockingTime = this.getLocalStorage('accountLockingTime')

				if (accountLockingTime) {
					const seconds = this.$moment(new Date())
						.diff(this.$moment(new Date(accountLockingTime)), 'seconds')

					if (seconds < 600) {
						let remaining = Math.round(10 - (seconds / 60))

						remaining = remaining === 0 ? 1 : remaining

						this.showToast(this.$options.filters.capitalize(this.$t('toastMsg.loginAttemptExceeded', [remaining, this.$tc('min', remaining > 1 ? 2 : 1)])), {
							title: this.$options.filters.capitalize(this.$t('loginExceeded')),
							variant: 'danger',
							toaster: 'b-toaster-top-center'
						})
					}

					this.removeLocalStorage('accountLockingTime')
				}

				this.loading = true

				this.$store.dispatch('login', data).then((response) => {
					if (response.code === 200) {
						this.removeLocalStorage('accountLockingTime')

						if ((this.inputType === 'mobile' && response.data.info.is_phone_verified) || (this.inputType === 'email' && response.data.info.is_email_verified)) {
							this.$refs.sidebar.hide()
						} else {
							this.otpMedium = this.inputType === 'mobile' ? `${this.userName.code} - ${this.userName.number}` : this.userName
							this.otpVerificationType = 'signup'
						}
					}

					this.loading = false
				}).catch((err) => {
					this.loading = false

					if ((data.gp_user_id || data.fb_user_id) && err.response && err.response.status === 401) {
						this.authComponent = 'signup'
						this.showToast(this.$options.filters.capitalize(this.$t('toastMessage.createAccount')), {
							title: this.$options.filters.capitalize(this.$t('emailNotReg')),
							variant: 'warning',
							toaster: 'b-toaster-top-center'
						})
					} else if (err.response && err.response.status === 403) {
						this.setLocalStorage('accountLockingTime', new Date())
					}

					this.showErrors(err)
				})
			},
			async validateLogin () {
				if (await this.$refs.validator.validate()) {
					if (this.inputType === 'mobile') {
						this.login({
							phone: {
								code: this.userName.code,
								number: this.userName.number
							},
							password: this.password
						})
					} else {
						this.login({
							email: this.userName,
							password: this.password
						})
					}
				}
			},
			async forgotPassword () {
				if ((await this.$refs[`${this.inputType}Validator`]?.validate())?.valid) {
					this.loading = true

					const payload = {}

					if (this.inputType === 'mobile') {
						payload.phone = {
							code: this.userName.code,
							number: this.userName.number
						}
					} else {
						payload.email = this.userName
					}

					this.$store.dispatch('forgotPassword', {
						...payload
					}).then((response) => {
						if (response.code === 200) {
							this.otpMedium = this.inputType === 'mobile' ? `${this.userName.code} - ${this.userName.number}` : this.userName
							this.otpVerificationType = 'forgot-password'
						}

						this.loading = false
					}).catch((err) => {
						this.showErrors(err)
						this.loading = false
					})
				}
			},
			connectSocialPlatform (platform) {
				this.loading = true

				switch (platform) {
					case 'facebook': {
						if (window.FB && window.FB.login) {
							const getFBUser = (response) => {
								if (response.status === 'connected') {
									window.FB.api('/me', {
										access_token: response.authResponse.accessToken,
										fields: 'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email'
									}, (fbUser) => {
										this.processSocialData({
											name: platform,
											type: this.authComponent,
											data: fbUser
										})
										this.email = fbUser.email
										this.name = fbUser.name
									})
								} else {
									this.processSocialData({
										name: platform,
										type: this.authComponent,
										data: null
									})
								}
							}

							window.FB.getLoginStatus((response) => {
								if (response.status === 'connected') {
									getFBUser(response)
								} else {
									window.FB.login(getFBUser, {
										scope: 'email',
										enable_profile_selector: true
									})
								}
							})
						} else {
							this.processSocialData({
								name: platform,
								type: this.authComponent,
								data: null
							})
						}

						break
					}
				}
			},
			processSocialData (platform) {
				this.loading = false

				if (platform.data) {
					if (this.authComponent === 'signup') {
						this[platform.name] = platform.data
					} else if (this.authComponent === 'login') {
						const key = platform.name === 'google' ? 'gp' : 'fb'

						this.login({
							[`${key}_user_id`]: platform.data.id,
							[`${key}_data`]: platform.data
						})
					}
				} else {
					this.showToast(this.$options.filters.capitalize(this.$t('toastMessage.platformError', [platform.name])), {
						title: `${this.$options.filters.capitalize(this.$t('sorry'))}!`,
						variant: 'danger',
						toaster: 'b-toaster-top-center'
					})
				}
			},
			destroy () {
				this.authComponent = null
				Object.assign(this.$data, initialState())
			}
		}
	}
</script>

<style lang="css" scoped>
	.fb-button {
		background-color: white;
		border-radius: 5px;
		padding:8px 10px;
		width:100%;
		font-size: 14px;
		text-align: center;
		color: #3c4043;
		cursor: pointer;
		border: 2px solid rgb(226, 224, 224);
	}
</style>
