<template>
	<div v-if="!otpVerified">
		<p>
			{{ $t('otpSentTo', [otpMedium]) }}
			<span v-if="type === 'forgot-password'">{{ $t('toResetPassword') }}</span>
		</p>
		<validation-observer ref="validator">
			<validation-provider
				v-slot="{ errors }"
				:name="$t('otp')"
				rules="required|min:6|max:6"
			>
				<b-form-group
					label-for="otp"
					:state="errors.length === 0 ? true : false"
					:invalid-feedback="errors[0]"
				>
					<b-form-input
						id="otp"
						v-model="otp"
						type="number"
						:placeholder="$options.filters.capitalize($t('enter otp'))"
						size="md"
						:state="errors.length ? false : null"
					/>
				</b-form-group>
			</validation-provider>
		</validation-observer>
		<b-button
			size="sm"
			variant="success"
			class="text-capitalize mb-3"
			:disabled="loading"
			block
			@click="verifyOtp"
		>
			{{ $t('verify') }}
		</b-button>
		<b-button size="sm" variant="outline-secondary" class="text-capitalize mb-3" block @click="$emit('done')">
			{{ $t('cancel') }}
		</b-button>
		<p class="text-muted text-center">
			<span v-if="seconds">{{ $t('otpExpiresIn') }} {{ $moment().startOf('day').seconds(seconds).format('mm:ss') }}</span>
			<span v-else>
				{{ $t('didntReceiveOtp') }}
				<b-link class="underline" @click="resendOtp">
					{{ $t('resend otp') }}
				</b-link>
			</span>
		</p>
	</div>
	<div v-else>
		<h5 class="mb-3">
			{{ $t('reset password') | capitalize }}
		</h5>
		<validation-observer ref="validator">
			<validation-provider
				v-slot="{ errors }"
				vid="password"
				:name="$t('password')"
				rules="required|min:6|max:18"
			>
				<b-form-group
					label-for="password"
					:state="errors.length === 0 ? true : false"
					:invalid-feedback="errors[0]"
				>
					<b-form-input
						id="password"
						v-model="password"
						:state="errors.length ? false : null"
						type="password"
						name="password"
						:placeholder="$options.filters.capitalize($t('enterNewPassword'))"
						size="lg"
					/>
				</b-form-group>
			</validation-provider>
			<validation-provider
				v-slot="{ errors }"
				:name="$t('password confirmation')"
				rules="required|min:6|max:18|equals:password"
			>
				<b-form-group
					label-for="password-confirmation"
					:state="errors.length === 0 ? true : false"
					:invalid-feedback="errors[0]"
				>
					<b-form-input
						id="password-confirmation"
						v-model="passwordConfirmation"
						:state="errors.length ? false : null"
						type="password"
						name="password-confirmation"
						:placeholder="$options.filters.capitalize($t('confirm new password'))"
						size="lg"
					/>
				</b-form-group>
			</validation-provider>
		</validation-observer>
		<b-button
			size="sm"
			variant="success"
			class="text-capitalize mb-3"
			:disabled="loading"
			block
			@click="resetPassword"
		>
			{{ $t('reset') }}
		</b-button>
	</div>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	const initialState = () => ({
		loading: false,
		timer: null,
		seconds: 300,
		otp: '',
		errorMsg: '',
		otpVerified: false,
		password: '',
		passwordConfirmation: ''
	})

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			type: {
				type: String,
				required: true
			},
			otpMedium: {
				type: String,
				default: ''
			}
		},
		data () {
			return initialState()
		},
		computed: {
			otpSource () {
				return this.$store.state.merchant.settings.general.registration_verification_source
			}
		},
		watch: {
			otp (otp) {
				if (otp.length) {
					this.errorMsg = ''
				}
			}
		},
		mounted () {
			this.initTimer()
		},
		destroyed () {
			clearInterval(this.timer)
		},
		methods: {
			initTimer () {
				this.timer = setInterval(() => {
					this.seconds--

					if (this.seconds <= 0) {
						clearInterval(this.timer)
					}
				}, 1000)
			},
			async verifyOtp () {
				if (await this.$refs.validator.validate()) {
					this.loading = true
					this.$store.dispatch('verifyOtp', {
						otp: this.otp
					}).then((response) => {
						if (response.code === 200) {
							if (this.type === 'signup') {
								this.$emit('done', true)
							} else if (this.type === 'forgot-password') {
								this.otpVerified = true
							} else {
								this.$emit('done', true)
								setTimeout(() => {
									this.showToast(this.$t('mobileUpdateSuccess'), {
										title: this.$t('success')
									})
								}, 500)
							}
						}
					}).catch((err) => {
						if (err.response && err.response.data) {
							this.$refs.validator.setErrors({
								[this.$t('otp')]: [err.response.data.message]
							})
						}
					}).finally(() => {
						this.loading = false
					})
				}
			},
			async resetPassword () {
				if (await this.$refs.validator.validate()) {
					this.loading = true
					this.$store.dispatch('resetPassword', {
						password: this.password,
						password_confirmation: this.passwordConfirmation
					}).then((response) => {
						if (response.code === 200) {
							this.$emit('done')
							setTimeout(() => {
								this.showToast(this.$t('toastMessage.loginWithNewPassword'), {
									title: this.$t('success')
								})
							}, 500)
						}

						this.loading = false
					}).catch((err) => {
						this.showErrors(err)
						this.loading = false
					})
				}
			},
			resendOtp () {
				this.loading = true
				this.$store.dispatch('resendOtp').then((response) => {
					if (response.code === 200) {
						clearInterval(this.timer)
						this.seconds = 300
						this.initTimer()
						this.focusInput(`#otp-verification.${this.type} input`)
					}

					this.loading = false
				}).catch((err) => {
					this.showErrors(err)
					this.loading = false
				})
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
